import {Link, Form} from '@remix-run/react';
import {
  Button,
  Container,
  Nav,
  Navbar as BootstrapNavbar,
} from 'react-bootstrap';

import {Logo} from '../atoms/logo';
import {NavLink} from '../atoms/nav-link';

export interface NavbarProps {
  activeKey: string;
  /** The current user's display name */
  displayName: Nullable<string>;
  /** Whether or not the current user is logged in */
  isLoggedIn: Nullable<boolean>;
}

export const Navbar = ({activeKey, displayName, isLoggedIn}: NavbarProps) => (
  <BootstrapNavbar bg="primary" variant="dark" expand="sm" fixed="top">
    <Container>
      <BootstrapNavbar.Toggle aria-controls="navbar-collapse" />

      <BootstrapNavbar.Brand as={Link} to="/">
        <Logo />
      </BootstrapNavbar.Brand>

      <BootstrapNavbar.Collapse id="navbar-collapse">
        <Nav activeKey={activeKey} className="ms-auto">
          <NavLink href="https://check-run-reporter.statuspage.io/">
            Status
          </NavLink>
          <NavLink href="/#o-2s-2-pricing">Pricing</NavLink>
          <NavLink href="/#o-2s-2-how-it-works">How it works</NavLink>
          <NavLink href="/docs">Documentation</NavLink>
          <NavLink href="/changelog">Changelog</NavLink>
          {isLoggedIn && <NavLink href="/repos">Repositories</NavLink>}
        </Nav>
      </BootstrapNavbar.Collapse>
      {isLoggedIn ? (
        <Form action="/auth/logout" method="post">
          <Button type="submit" className="btn btn-outline-light">
            <span className="visually-hidden">Logged in as {displayName}.</span>
            Logout
          </Button>
        </Form>
      ) : (
        <Button href="/auth/github" className="btn btn-outline-light">
          Login
        </Button>
      )}
    </Container>
  </BootstrapNavbar>
);
