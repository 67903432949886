import {Col, Container, Nav, Row} from 'react-bootstrap';
import {useLocation} from 'react-router';

import {NavLink} from '../atoms/nav-link';

export const Footer = () => {
  const location = useLocation();

  return (
    <div className="footer">
      <Container>
        {location.pathname === '/' && (
          <Row>
            <Col>
              <hr />
            </Col>
          </Row>
        )}

        <footer className="d-flex justify-content-between">
          <div className="flex-fill">
            &copy; Ian Remmel, LLC 2019 - {new Date().getFullYear()}
          </div>
          <Nav
            activeKey={location.pathname}
            className="flex-fill d-flex justify-content-end"
          >
            <NavLink href={'/about'}>About Us</NavLink>
            <NavLink href={'/privacy'}>Privacy</NavLink>
            <NavLink href={'/terms'}>Terms</NavLink>
            <NavLink href={'https://github.com/check-run-reporter/feedback'}>
              Contact
            </NavLink>
          </Nav>
        </footer>
      </Container>
    </div>
  );
};
