import { LocaleProvider, Section } from '@code-like-a-carpenter/components';
import type { MetaFunction } from '@remix-run/node';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData } from
'@remix-run/react';
import type { DataFunctionArgs } from '@remix-run/server-runtime';
import { withSentry } from '@sentry/remix';
import { useMemo } from 'react';

import { loadUserOrNull } from './auth/loaders.server';
import { Footer } from './components/molecules/footer';
import { Navbar } from './components/molecules/navbar';
// @ts-ignore - typescript doesn't _always_ hit this line, so don't expect-error
// here
import styles from './root.css';

export const meta: MetaFunction = () => [
{
  content: 'Check Run Reporter',
  name: 'title'
}];


export const links = () => [
{
  href: styles,
  rel: 'stylesheet'
}];


export async function loader(args: DataFunctionArgs) {
  const user = await loadUserOrNull(args);
  const locale =
  args.request.headers.get('accept-language')?.split(',')[0] ?? 'en-US';
  return { locale, user };
}

export default withSentry(() => {
  const { locale: localeName, user } = useLoaderData<typeof loader>();

  const locale = useMemo(() => {
    const detectedLocaleName =
    (typeof navigator === 'undefined' ? localeName : navigator.language) ??
    localeName;

    try {
      return new Intl.Locale(detectedLocaleName);
    } catch (err) {
      console.error(
        `Failed to initialize locale localeName=${localeName} detectedLocaleName=${detectedLocaleName} isSSR=${
        typeof window === 'undefined'}`,

        err
      );

      return new Intl.Locale('en-US');
    }
  }, [localeName]);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <LocaleProvider locale={locale}>
          <div id="root">
            <Navbar
              activeKey="/"
              displayName={user?.displayName || null}
              isLoggedIn={!!user?.externalId} />

            <Section>
              <Outlet />
            </Section>
            <Footer />
          </div>
          <ScrollRestoration />
          <Scripts />
          {process.env.NODE_ENV === 'development' && <LiveReload />}
        </LocaleProvider>
      </body>
    </html>);

});

export { ReusableErrorBoundary as ErrorBoundary } from './components/organisms/reusable-error-boundary';